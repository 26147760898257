import { useTranslation } from 'next-i18next'
import { MagicLinkLayoutSchema } from '../../../lib/contentful/datamodel/layouts'
import { PageInfo } from '../../../lib/transformation/aggregateShopLocale/types'
import { useAggregatedShopLocale } from '../../../providers/aggregatedShopLocale'

export const useRedirectSlug = (
  redirectType: MagicLinkLayoutSchema['redirectType'],
  redirectTo: MagicLinkLayoutSchema['redirectTo']
) => {
  const aggregatedShopLocale = useAggregatedShopLocale()
  const { t } = useTranslation()

  if (redirectType === 'Contentful Page') {
    // Determine if the redirect target is referenced in the requested ShopLocale
    // to avoid transitions to 404 pages

    if (redirectTo?.slug) {
      const allPages = [
        ...Object.values(aggregatedShopLocale.commonPages),
        ...aggregatedShopLocale.localeSpecificPages,
      ].filter(Boolean) as PageInfo[]

      const allSlugs = allPages.map(page => page.slug)
      const isReferencedFromShopLocale = allSlugs.includes(redirectTo.slug)

      if (isReferencedFromShopLocale) {
        return redirectTo.slug
      }
    }
  } else if (redirectType === 'Cart Page') {
    return t('magiclink:cartSlug')
  } else if (redirectType === 'Checkout Page') {
    return t('magiclink:checkoutSlug')
  }

  // Fall back to locale main page
  return aggregatedShopLocale.commonPages.mainPage.slug
}

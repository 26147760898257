import { useEffect, useState } from 'react'
import { normalizeProduct } from '../../../lib/commercetools/normalizers/normalizeProduct'
import { getProductsBySku } from '../../../lib/commercetools/requests/products/getProductsBySku'
import { useEcomLocale } from '../../../providers/locale'

export const useResolvedProducts = (skuList: string[] | undefined) => {
  const lcCC = useEcomLocale()
  const [state, setState] = useState<
    'waitingForSkuList' | 'resolving' | 'done'
  >('waitingForSkuList')

  const [products, setProducts] = useState<NormalizedProduct[] | undefined>()

  useEffect(() => {
    if (state === 'waitingForSkuList' && skuList) {
      if (skuList.length === 0) {
        setState('done')
        return
      }

      setState('resolving')

      getProductsBySku(skuList, lcCC).then(response => {
        if (response.result === 'successful') {
          setProducts(
            response.data.results.map(bxProduct =>
              normalizeProduct(bxProduct, lcCC)
            )
          )
          setState('done')
        } else {
          throw response.error
        }
      })
    }
  }, [state, skuList, lcCC])

  return {
    state,
    products,
  }
}

import { useUrlQuery } from '../../../providers/urlQuery'

export const useUrlSkuList = () => {
  const skuList = useUrlQuery().getUrlParam('sku')

  /**
   * FIXME: define the MagicLink API for an skuList so we can use the
   * `getUrlParamAsArray` or `getUrlParamAsSingleton` implementation from `useUrlQuery()`
   * The API should either only accept a stringified list like `?sku=9990000005,9990000006,9990000007`
   * or a query param Array like `?sku=9990000005&sku=9990000006&sku=9990000007`
   */
  if (skuList && typeof skuList === 'string') {
    return skuList.split(',')
  } else if (Array.isArray(skuList)) {
    return skuList
  } else {
    return []
  }
}

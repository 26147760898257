import { CartAction } from '..'
import { normalizeCart } from '../../../lib/commercetools/normalizers/normalizeCart'
import { clearCart } from '../../../lib/commercetools/requests/carts/clearCart'

export const clearCartAction =
  (): CartAction<unknown> =>
  async ({ lcCC, fetchOrCreateCart, logError, replaceCart }) => {
    const cart = await fetchOrCreateCart()

    const clearCartResponse = await clearCart(
      lcCC,
      cart.id,
      cart.version,
      cart.lineItems.map(item => item.id)
    )

    if (clearCartResponse.result !== 'successful') {
      logError(
        clearCartResponse.error,
        'clearCartActionAction',
        clearCartResponse.data
      )
      return
    }

    replaceCart(normalizeCart(clearCartResponse.data, lcCC))
  }

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useIsAudioLibraryCheckout } from '../../hooks/useIsAudioLibraryCheckout'
import { sentryWithExtras } from '../../hooks/useSentry'
import { MagicLinkLayoutSchema } from '../../lib/contentful/datamodel/layouts'
import { Aggregated } from '../../lib/transformation/aggregatePage/aggregate'
import { LoadingLayout, Message } from '../LoadingLayout'
import { useMagicLinkProcess } from './hooks/useMagicLinkProcess'
import { useRedirectSlug } from './hooks/useRedirectSlug'
import { useResolvedProducts } from './hooks/useResolvedProducts'
import { useUrlSkuList } from './hooks/useUrlSkuList'

export type MagicLinkLayoutProps = Aggregated<MagicLinkLayoutSchema> & {
  continuePrompt?: string
  addDiscountCodeMessage?: string
}

export const MagicLinkLayout: FunctionComponent<MagicLinkLayoutProps> = ({
  pageMetaData,
  addProducts,
  addDiscountCode,
  addDiscountCodeMessage,
  redirectType,
  redirectTo,
  continuePrompt,
}) => {
  const isAudioLibraryCheckout = useIsAudioLibraryCheckout()
  const audioLibraryUrl = process.env.NEXT_PUBLIC_MY_TONIES_URL
  const { t } = useTranslation()
  // extract optional SKU list from URL

  const urlSkuList = useUrlSkuList()
  const urlProducts = useResolvedProducts(urlSkuList)

  // State for continue prompt
  // When true, the visitor will have to click
  // a continue button before the magic starts.

  const [hasContinuePrompt, setHasContinuePrompt] = useState(
    continuePrompt !== undefined
  )

  // Compute the redirect target

  const redirectSlug = useRedirectSlug(redirectType, redirectTo)

  // Build the product list

  const allProducts: NormalizedProduct[] = useMemo(
    () => [
      ...(addProducts?.normalizedProducts || []),
      ...(urlProducts.products || []),
    ],
    [addProducts, urlProducts.products]
  )

  // Evaluate if we are ready to start the MagicLink process

  const isReadyToStart =
    // if there is a prompt, wait for the user to confirm
    !hasContinuePrompt &&
    // wait for URL products to be resolved
    urlProducts.state === 'done'

  // Define the MagicLink process

  const { info, error, cartQueueError, magicLinkQueueError, magicLinkParams } =
    useMagicLinkProcess(
      isReadyToStart,
      allProducts,
      addDiscountCode
        ? {
            code: addDiscountCode,
            message: addDiscountCodeMessage || t('magiclink:applyingDiscount'),
          }
        : undefined,
      redirectSlug
    )

  const { slug, code, sku } = magicLinkParams

  const message = useMemo<Message>(() => {
    if (hasContinuePrompt) {
      return {
        text: continuePrompt || '',
        type: 'error',
      }
    } else if (error) {
      return {
        text: t('magiclink:error'),
        type: 'error',
      }
    } else {
      return {
        text: info,
        type: 'progress',
      }
    }
  }, [continuePrompt, error, hasContinuePrompt, info, t])

  // Log all errors

  useEffect(() => {
    if (error || (message && message.type === 'error')) {
      const stringSkus = sku?.toString()
      console.error(`MagicLink Failure!, ${message.text}`, {
        sku,
        code,
        slug,
        redirectSlug,
        error,
        cartQueueError,
        magicLinkQueueError,
        isAudioLibraryCheckout,
      })

      sentryWithExtras(
        'MagicLink Failure!',
        new Error(`MagicLink Failure!: ${message.text}`),
        {
          sku: stringSkus,
          code,
          slug,
          redirectSlug,
          cartQueueError,
          magicLinkQueueError,
          isAudioLibraryCheckout,
        }
      )
    }
  }, [
    cartQueueError,
    code,
    continuePrompt,
    error,
    hasContinuePrompt,
    isAudioLibraryCheckout,
    magicLinkQueueError,
    message,
    redirectSlug,
    sku,
    slug,
  ])

  // Compute message text and type

  const redirectLink = {
    text: t('magiclink:proceedToWebsite'),
    href:
      isAudioLibraryCheckout && audioLibraryUrl
        ? audioLibraryUrl
        : redirectSlug,
  }

  const link = message.type === 'error' ? redirectLink : undefined

  return (
    <LoadingLayout
      message={message}
      pageMetaData={{
        ...pageMetaData,
        isCacheable: false,
        includeInSitemapXml: false,
      }}
      link={link}
      hasContinuePrompt={hasContinuePrompt}
      setHasContinuePrompt={setHasContinuePrompt}
    />
  )
}

import { ProductQueryResult } from './types'
import { stringify } from '../../util/stringify'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'
import { normalizeProduct } from '../../normalizers/normalizeProduct'
import { getCurrencyCodeByLocale } from '../../../../config/shopAPI'

export const getProductsBySku = async (skuList: string[], lcCC: EcomLocale) => {
  // SKUs which work on DEV:
  // 10000039_ct_fireman_uk
  // 10000037_ct_unicorn_uk
  // 10000038_ct_footballer_uk
  // 10000055_starter_set_grey_uk
  // 10000053_starter_set_light_blue_uk
  //
  // SKUs which work on PROD:
  // 10000152_disney_frozen
  // 10000311_peppa-pig_on-the-road
  // 10000293_elmer_elmer_and_friends_story_collection
  // 10000153_disney_toy-story
  // 10000001_the_gruffalo

  if (skuList.length === 0) {
    throw new Error('Empty SKU list')
  }

  let where = `masterData(current(masterVariant(sku in ${stringify(skuList)})))`

  /**
   * TODO
   * after migration of commercetools UK in DE
   * UK currently has no prices(country= but "Any"
   */
  const priceCountry = lcCC.substring(3, 5)
  if (priceCountry !== 'GB') {
    where += ` AND masterData(current(masterVariant(prices(country="${priceCountry}"))))`
  }

  const response = await fetch<Record<string, unknown>, ProductQueryResult>({
    query: '/products',
    lcCC,
    method: 'get',
    request: {
      limit: 500,
      where: where,
      expand: 'masterData.current.categories[*].parent',
      priceCountry: lcCC.substr(3, 2),
      priceCurrency: getCurrencyCodeByLocale(lcCC),
    },
  })

  if (response.result === 'successful' && !response.data.results) {
    throw new Error(
      'getProductsBySku result is null for skuList:' + stringify(skuList)
    )
  }

  if (response.result === 'successful') {
    // Sort results in query order

    response.data.results.sort((product1, product2) => {
      const sku1 = normalizeProduct(product1, lcCC).sku
      const sku2 = normalizeProduct(product2, lcCC).sku

      const index1 = skuList.indexOf(sku1)
      const index2 = skuList.indexOf(sku2)

      return index1 - index2
    })
  }

  return response
}

import { BxCart } from '../bxTypes'
import { fetch } from '../../util/fetch'
import { EcomLocale } from '../../../../config/shopAPI/types'

type Request = {
  version: number
  actions: {
    action: 'removeLineItem'
    lineItemId: string
  }[]
}

/**
 * clears the cart in CommerceTools and returns its state.
 */
export const clearCart = async (
  lcCC: EcomLocale,
  cartId: string,
  cartVersion: number,
  lineItemIds: string[]
) =>
  fetch<Request, BxCart>({
    query: `/carts/${cartId}`,
    lcCC,
    method: 'post',
    request: {
      version: cartVersion,
      actions: lineItemIds.map(id => ({
        action: 'removeLineItem',
        lineItemId: id,
      })),
    },
  })

import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useCartActions } from '../../../hooks/useCartActions'
import { useIsAudioLibraryCheckout } from '../../../hooks/useIsAudioLibraryCheckout'
import { useQueue } from '../../../hooks/useQueue'

import { useAuth } from '../../../providers/auth'
import { addDiscountAction } from '../../../providers/cartActions/actions/addDiscountAction'
import { addProductAction } from '../../../providers/cartActions/actions/addProductAction'
import { clearCartAction } from '../../../providers/cartActions/actions/clearCartAction'
import { useGtmV4 } from '../../../providers/gtmV4'
import { useCreateDebugLogForMagicLink } from './useCreateDebugLogForMagicLink'
import { useProductConfirmation } from '../../../hooks/useProductConfirmation'
import { useRecommendations } from '../../../providers/recommendations'

const delay = (milliseconds: number) =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(true)
    }, milliseconds)
  })

export const useMagicLinkProcess = (
  isReady: boolean,
  products: NormalizedProduct[],
  discount:
    | {
        code: string
        message: string
      }
    | undefined,
  redirectSlug: string
) => {
  const router = useRouter()
  const { pushGtmV4Event } = useGtmV4()
  const { t } = useTranslation()
  const magicLinkQueue = useQueue()
  const cartQueue = useCartActions()
  const confirmProduct = useProductConfirmation()
  const [info, setInfo] = useState('')
  const isStarted = useRef(false)
  const { isPending: isPendingAuth, user, login } = useAuth()
  const isAudioLibraryCheckout = useIsAudioLibraryCheckout()
  const magicLinkParams = useCreateDebugLogForMagicLink(
    discount?.code,
    products.map(product => product.sku)
  )
  const { handleSetLastSeenSku } = useRecommendations()
  useEffect(() => {
    if (isPendingAuth || !isReady || isStarted.current) {
      return
    }
    isStarted.current = true

    if (isAudioLibraryCheckout && !isPendingAuth && !user) {
      /**
       * audioLibraryCheckout is only allowed for logged in users, so we try to login or send the user to login
       */
      magicLinkQueue.push(async () => {
        setInfo(t('magiclink:unauthenticated'))
        await delay(1000) // guarantee a minimum duration for this text to show before proceeding or showing errors
      })

      login()
      return
    }

    if (products.length > 0) {
      magicLinkQueue.push(async () => {
        setInfo(t('magiclink:addingProducts'))
        await delay(300) // guarantee a minimum duration for this text to show before proceeding or showing errors
      })

      if (isAudioLibraryCheckout) {
        /**
         * we clear the tunes cart before adding a new tunes because
         * we currently allow only one tunes item to be checked out at a time
         */
        magicLinkQueue.push(async () => await cartQueue.push(clearCartAction()))
      }

      for (const product of products) {
        magicLinkQueue.push(async () => {
          await confirmProduct(product)
            .then(({ product, custom }) => {
              handleSetLastSeenSku(product.sku)
              cartQueue
                .push(
                  addProductAction({
                    product,
                    quantity: 1,
                    custom,
                    pushGtmV4Event,
                  })
                )
                .then(result => {
                  if (result) {
                    return result
                  } else {
                    throw new Error(
                      `Error adding product to cart: SKU "${product.sku}"`
                    )
                  }
                })
            })
            .catch(() => {
              /**
               * In case the visitor closes the RepairModal (= reject), we
               * don't want to throw but continue with the MagicLink process.
               */
              return
            })
        })
      }
    }

    if (discount) {
      magicLinkQueue.push(async () => {
        setInfo(discount.message)
        await delay(300) // guarantee a minimum duration for this text to show before proceeding or showing errors
      })

      magicLinkQueue.push(async () => {
        const result = await cartQueue.push(
          addDiscountAction(discount.code, pushGtmV4Event)
        )

        if (result !== 'ok') {
          throw new Error(
            `Error applying discount code "${discount.code}": ${result}`
          )
        }

        return result
      })
    }

    magicLinkQueue.push(async () => {
      setInfo(t('magiclink:redirecting'))
      await delay(300) // guarantee a minimum duration for this text to show before proceeding or showing errors
    })

    magicLinkQueue.push(async () => {
      const { sku, ...query } = router.query

      // Push to GTM
      pushGtmV4Event({
        eventType: 'magicLink',
        items: products,
        coupon: discount?.code,
      })

      router.replace({ pathname: redirectSlug, query })
    })
  }, [
    products,
    discount,
    redirectSlug,
    router,
    magicLinkQueue,
    t,
    cartQueue,
    pushGtmV4Event,
    isReady,
    isPendingAuth,
    isAudioLibraryCheckout,
    user,
    login,
    magicLinkParams,
    confirmProduct,
    handleSetLastSeenSku,
  ])
  return {
    info,
    error: magicLinkQueue.error,
    //Parameters below are used for more detailed sentry error tracking
    cartQueueError: cartQueue.error?.message || 'no Error',
    magicLinkQueueError: magicLinkQueue.error?.message || 'no Error',
    magicLinkParams: magicLinkParams,
  }
}

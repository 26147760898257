import { useUrlQuery } from 'providers/urlQuery'

export type MagicLinkParamsNames =
  | 'slug'
  | 'sku'
  | 'locale'
  | 'context'
  | 'session_state'
  | 'code'
  | 'cookieConsent'
  | 'state'

export type MagicLinkParams = {
  slug?: string
  sku?: string[]
  locale?: string
  context?: string
  session_state?: string
  code?: string
  cookieConsent?: string
  state?: string
}

export function useCreateDebugLogForMagicLink(
  discountCode?: string,
  skuList?: string[]
) {
  let magicLinkParams: MagicLinkParams = {}
  const { getUrlParamAsArray, getUrlParamAsSingleton } = useUrlQuery()
  const parameterList = [
    'slug',
    'sku',
    'locale',
    'context',
    'state',
    'session_state',
    'code',
    'cookieConsent',
  ]
  parameterList.forEach(p => {
    const param = getUrlParamAsSingleton(p)
    const sku = getUrlParamAsArray(p)

    if (param !== undefined && p !== 'sku') {
      magicLinkParams = { ...magicLinkParams, [p]: param }
    }
    if (p === 'sku') {
      magicLinkParams = {
        ...magicLinkParams,
        sku:
          skuList && skuList.length > 0
            ? [...new Set([...skuList, ...sku])].filter(
                item => item !== undefined
              )
            : sku,
      }
    }
    if (p === 'code' && discountCode) {
      magicLinkParams = {
        ...magicLinkParams,
        code: discountCode,
      }
    }
  })
  return magicLinkParams
}

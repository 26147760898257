import React, { FunctionComponent, Dispatch, SetStateAction } from 'react'
import styled, { useTheme } from 'styled-components'
import { Icon, Button, Paragraph, ProgressIndicator } from '@boxine/tonies-ui'
import * as icons from '@boxine/tonies-ui/icons'
import { PageMetaData } from '../../lib/contentful/datamodel/schemas'
import { PageLayout } from '../PageLayout'
import { useTranslation } from 'next-i18next'

export type Message = {
  text: string
  type: 'error' | 'progress' | 'success'
}

export type LoadingLayoutProps = {
  link?: {
    href: string
    text: string
  }
  message?: Message
  pageMetaData?: PageMetaData
  hasContinuePrompt?: boolean
  setHasContinuePrompt?: Dispatch<SetStateAction<boolean>>
}

const Wrapper = styled.div`
  align-content: center;
  display: grid;
  gap: 2rem;
  height: 100vh;
  justify-items: center;
  width: 100vw;
`

const MessageBox = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  padding: 0 1rem;

  svg {
    margin-top: 0.2em;
  }

  p {
    margin-left: 0.5rem;
  }
`

export const LoadingLayout: FunctionComponent<LoadingLayoutProps> = ({
  link,
  message,
  pageMetaData,
  hasContinuePrompt,
  setHasContinuePrompt,
}) => {
  const { t } = useTranslation()
  const { colors } = useTheme()

  return (
    <PageLayout
      dataTestId="loadingpage-pagelayout"
      meta={pageMetaData}
      header={<></>}
      footer={<></>}
      isMinimalHeaderVersion
      isMinimalFooterVersion
      preventRemoveAlreadyBoughtTunes
      hasSideCartAutoExpandOnAddToCart={false}
      preventScrollingHeadsInFooter
    >
      <Wrapper>
        <Icon type={icons.toniesLogo} width="8rem" fillAll={colors.primary} />
        {message && (
          <MessageBox>
            {message.type === 'error' && (
              <Icon type={icons.errorLegacy} width="1rem" height="1rem" />
            )}
            <Paragraph
              size={2}
              color={message.type === 'error' ? undefined : 'primary'}
            >
              {message.text}
            </Paragraph>
          </MessageBox>
        )}
        {message?.type !== 'error' && !hasContinuePrompt && (
          <ProgressIndicator
            data-testid="checkout-progress-indicator"
            styling="secondary"
            height="2rem"
            width="2rem"
          />
        )}
        {hasContinuePrompt && setHasContinuePrompt && (
          <Button
            onClick={() => setHasContinuePrompt(false)}
            data-testid="loading-page-button-continue"
          >
            {t('magiclink:continue')}
          </Button>
        )}
        {link && (
          <Button as="a" variant="secondary" href={link.href}>
            {link.text}
          </Button>
        )}
      </Wrapper>
    </PageLayout>
  )
}
